.upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 89vh;
}

.upload div {
    background-color: skyblue;
    height: 380px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    padding: 80px;
    border-radius: 8px;
    ;
}

.upload div input {
    width: 100%;
    min-height: 40px;
    border: none;
    padding: 5px 10px;
}

.upload div button {
    width: 100%;
    height: 40px;
    border: none;
    font-size: 18px;
    padding: 5px;
}

#selectMenu {
    padding: 10px;
    width: 100%;
    border: none;

}

.ProgressBar {
    height: 100px !important;
}