.volunteerContainer {
    margin: 0;
    padding: 0;
}

.volunteerWrapper {
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.volunteerWrapper form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 40px 30px 40px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 8px;
}

.volunteerWrapper h2 {
    font-family: 'Josefin Sans', sans-serif;
}

.volunteerWrapper form input {
    width: 100%;
    height: 40px;
    margin: 10px auto;
    padding: 5px 10px;
    border: none;
    background-color: rgb(236, 235, 235);
    border-radius: 4px;
}

.volunteerWrapper form input[type="submit"] {
    background-color: #668dc0;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 20px;
}

.volunteerWrapper form input[type="submit"]:hover {
    background-color: #567eb3;
}

.volunteerWrapper form small {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.volunteerWrapper form a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #567eb3;
}

.volunteerWrapper form a:hover {
    color: red;
}