.footer {
    height: 100px;
    background-color: #343a3f;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    bottom: 0;
    width: 100%;
}

.footer small {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: normal;

}