.navigation img {
    width: 40vw;
    max-width: 200px;
}

.navigationBar a {
    font-size: 18px;
    color: #28578f !important;
    font-family: 'Josefin Sans', sans-serif;
    margin-left: 1vw;
}

.navigation a:hover {
    color: #1d487c !important;
}

.navigationBar #loginBtn {
    border-color: #1d467c !important;
    border-radius: 5px;
    color: #fff !important;
    background-color: #1d467c;
    padding: 8px 16px;
    margin-left: 1vw;
    font-size: 16.2px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 700;
    line-height: 1.5;
    transition: all 0.2s;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.25px;
    border: 2px solid;
    -webkit-box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    max-width: 100px;
}

.navigationBar #loginBtn:hover {
    background-color: #153b6d;
    border-color: #153b6d;
}