.registration {
    min-height: 89.5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../assets/images/login.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.registration form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 40px 20px 40px;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 8px;
    background-color: #fff;
}

.registration form input {
    width: 100%;
    height: 40px;
    margin: 10px auto;
    padding: 5px 10px;
    border: none;
    background-color: rgb(236, 235, 235);
    border-radius: 4px;
}

.registration form input[type="submit"] {
    background-color: #668dc0;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: 1px;
    margin-top: 20px;
}

.registration form input[type="submit"]:hover {
    background-color: #567eb3;
}

.registration form small {
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
}

.registration form a {
    text-decoration: none;
    font-weight: bold;
    font-size: 16px;
    color: #567eb3;
}

.registration form a:hover {
    color: red;
}