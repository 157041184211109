.selectWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 450px;
    margin: 0 auto;
}

label {
    font-size: 1.1rem;
    font-weight: 600;
}

.selectDropdown,
.selectDropdown * {
    margin: 0 auto;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    width: 300px;
}

.selectDropdown {
    position: relative;
    background-color: #E6E6E6;
    border-radius: 4px;

}

.selectDropdown select {
    font-size: 1rem;
    font-weight: normal;
    max-width: 100%;
    padding: 8px 24px 8px 10px;
    border: none;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 40px !important;
}

.selectDropdown select:active,
.selectDropdown select:focus {
    outline: none;
    box-shadow: none;
}

.selectDropdown:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 0;
    height: 0;
    margin-top: -2px;
    border-top: 5px solid #aaa;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}