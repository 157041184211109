.proofContainer {
    background: url(../../assets/images/books.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 75vh;
}

.proofContainer .proofWrapper {
    min-height: 75vh;
    background-color: rgba(187, 186, 186, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.proofContainer .proofWrapper h2 {
    text-align: center;
    color: #434443;
    font-size: 32px;
    font-family: Josefin Sans;
    font-weight: 700;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 1rem;
}

.proofContainer .proofWrapper p {
    margin-top: 0;
    margin-bottom: 30px;
    text-align: center;
    color: #2b2b2b;
    font-family: Josefin Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    position: relative;
    letter-spacing: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
}