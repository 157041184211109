.heroWrapper {
    position: relative;
    border-bottom: 15px solid #C4c5c7;
    height: 92vh;
}

.heroWrapper img {
    display: block;
    height: 100%;
    max-height: none;
    max-width: none;
    position: static;
    visibility: visible;
    width: 100%;
    object-fit: cover;
}

.heroWrapper .midText {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.heroWrapper .midText h1 {
    font-size: 60px;
    font-family: 'Josefin Sans', sans-serif;
    line-height: 1.2;
    font-weight: 700;
    color: #ECF0F1;
}

.heroWrapper .midText h4 {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.6;
    letter-spacing: normal;
    font-family: Josefin Sans;
    color: #ECF0F1;
}

.heroWrapper .midText #loginBtn {
    border-color: #1d467c !important;
    border-radius: 5px;
    color: #fff !important;
    background-color: #1d467c;
    padding: 8px 16px;
    font-size: 18px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 600;
    line-height: 1.5;
    transition: all 0.2s;
    text-align: center;
    vertical-align: middle;
    letter-spacing: 0.4px;
    border: 2px solid;
    -webkit-box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
    margin-top: 10px;
    min-width: 200px;
    margin: 10px;
}

.heroWrapper .midText #loginBtn:hover {
    background-color: #153b6d;
    border-color: #153b6d;
}

@media screen and (max-width: 480px) {
    .heroWrapper .midText h1 {
        display: none;
    }
}