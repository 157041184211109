.sizerContainer {
    background-image: url(../../assets/images/sizerBG.jpg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 260px;
    margin-bottom: 100px;

}

.sizerContainer .sizerWrapper {
    background-color: rgba(40, 87, 143, 0.6);
    height: 260px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.sizerContainer h3 {

    color: #fff;
    text-align: center;
    font-style: italic;
    font-family: Josefin Sans;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 30px;
    letter-spacing: normal;
}